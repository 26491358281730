import { render, staticRenderFns } from "./project-preview-new.vue?vue&type=template&id=4777e785&scoped=true&"
import script from "./project-preview-new.vue?vue&type=script&lang=js&"
export * from "./project-preview-new.vue?vue&type=script&lang=js&"
import style0 from "./project-preview-new.vue?vue&type=style&index=0&id=4777e785&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4777e785",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KIcon from '@web/components/core/k-icon'
import KTechnologyChip from '@web/components/core/k-technology-chip'
installComponents(component, {KIcon,KTechnologyChip})
